import BulletPoint from "../components/global/BulletPoint"
import CardsSection from "../components/sections/CardsSection/CardsSection"
import CtaSection from "../components/sections/CtaSection"
import FaqAccordionSection from "../components/sections/FaqAccordionSection"
import GeneralHeader from "../components/global/GeneralHeader"
import Layout from "../components/global/Layout"
import QuoteSection from "../components/sections/QuoteSection/QuoteSection"
import React from "react"
import SectionWrapper from "../components/sections/SectionWrapper"
import ValuePropsSection from "../components/sections/ValuePropsSection"
import { queries }  from "../breakpoints"
import styled from 'styled-components';

const GroupOrders = ({ location }) => {

  const valueProps = [
    {
      imageSrc:"https://res.cloudinary.com/grubhub-marketing/image/upload/v1649353678/HERMES/2022/B2B/GHCORP/B2B%20Corp%20Website/March%208%20Handoff/Group%20Ordering/EXPORT-GroupOrder-ValueProp1.png",
      headline:"Enjoy delicious savings on delivery",
      subheadline:"Pay one delivery fee per restaurant instead of fees for each individual order."
    },
    {
      imageSrc:"https://res.cloudinary.com/grubhub-marketing/image/upload/v1649353678/HERMES/2022/B2B/GHCORP/B2B%20Corp%20Website/March%208%20Handoff/Group%20Ordering/EXPORT-GroupOrder-ValueProp2.png",
      headline:"Cater to every taste",
      subheadline:"Grubhub has the most local picks compared to any other platform, which means you’re only an order away from discovering a new favorite spot."
    },
    {
      imageSrc:"https://res.cloudinary.com/grubhub-marketing/image/upload/v1632933621/Grubhub%20Corporate%20Website/2021%20%E2%80%94%20Citrus/Illustrations/C-GroupOrdering/EXPORTS-Illustrations-GroupOrdering-DeliveryBag-1-Citrus.png",
      headline:"Cut food waste and overspending",
      subheadline:"Order the exact amount of food you need for each meal and set per-person spending limits."
    }
  ]

  const bulletPoints = [
    {
      heading: "Scheduled Team Order",
      body:"Account admins manage the budget, send out restaurant choices and check out for everyone. Meals arrive at the same time, individually bagged and labeled with employee names for convenience."
    },
    {
      heading: "Group Order",
      body:"Anyone on your team can start a shared cart. Employees order together from a restaurant and use their meal budget to split the cost at checkout."
    }
  ]

  const cards = [
    {
      title: "Catering",
      description: "Feed any occasion by preordering meals in advance or ordering on demand for same-day meetings.",
      link: "/catering/",
      imageSrc: "https://res.cloudinary.com/grubhub-marketing/image/upload/v1652231061/HERMES/2022/B2B/GHCORP/B2B%20Corp%20Website/May%2011%20Handoff%20-%20NO%20HOUSE%20UPDATE/Homepage/EXPORTS-CARD-Catering.jpg",
      linkText: "Learn more"
    },
    {
      title: "Individual Meals",
      description: "Employees can order meals to the office, home or wherever they’re working—you set the budget and control spend.",
      link: "/individual-meals/",
      imageSrc: "https://res.cloudinary.com/grubhub-marketing/image/upload/f_auto/v1626794994/Grubhub%20Corporate%20Website/2021/Photography%20Elements/1-WaysToOrder-Homepage/EXPORTS-Photography-WaysToOrder-Homepage-IndividualMeals.jpg",
      linkText: "Learn more"
    },
  ]

  const faqs = [
    {
      header: "What is a group order?",
      description: "A Group Order is a shared link that your team can use to place orders together using their meal perks. Orders arrive together, individually packed and labeled."
    },
    {
      header: "Can you do a group order on Grubhub?",
      description: `<span>Yes, you can do a <a href="/group-orders/">Group Order</a> on Grubhub. With a <a href="/">Grubhub Corporate Account</a> your team can easily order individually via a shared cart.</span>`
    },
    {
      header: "How does a group order work?",
      description: `<span>Grubhub group orders are offered through <a href="/">Grubhub Corporate Accounts</a>. Employees can place individual orders from pre-selected restaurants and the orders will get delivered together at the same time. Each order will arrive individually bagged and labeled for easy distribution. Learn more about <a href="/group-orders/">group ordering</a> and start an order today.</span>`
    },
    {
      header: "Do Grubhub group orders have any diner or order minimums?",
      description: `<span>With more group ordering options on Grubhub Corporate than on any other platform, you can feed any occasion while saving on fees and time. Plus there's no minimums for number of diners or orders.</span>`
    }
  ]

  return (
    <Layout
      location={location}
      title={"Corporate Group Orders | Grubhub Corporate"}
      description={"Discover Grubhub Corporate Account group orders. Your team can order whatever they're craving, delivered together, packaged separately."}
      className="group-orders-page"
      utag_data={{
        pageGroup: "grubhub - lead generation",
        subGroup: "ways to order",
        pageName: "group ordering",
        brand: "grubhub"
      }}
      faqSeoList={faqs}
    >
      <GeneralHeader
        headline="Group Ordering"
        preheader="Ways to order"
        textColor="var(--color-secondary)"
        background="rgba(186,228,238,0.2)"
        formID={1787}
        backgroundColorForm='#FFF'
        formHeading="Sign up to learn more"
        type="half-image"
        imageSrc="https://res.cloudinary.com/grubhub-marketing/image/upload/f_auto/v1626796712/Grubhub%20Corporate%20Website/2021/Header%20Images/Subpage%20Headers/HEADERS-SUBPAGES-2560x1707-GroupOrdering.jpg"
        alt="White desk with sushi, chips and guacamole, a soda can, a glass of pink lemonade and a burrito bowl with multiple hands reaching for the food items."
      />
      <ValuePropsSection
        headline="Let your team order together"
        subheadline="With more group ordering options on Grubhub Corporate than on any other platform, you can feed any occasion while saving on fees and time. Plus there's no minimums for number of diners or orders."
        valueProps={valueProps}
        layout="vertical"
      />
      <SectionWrapper
        backgroundColor="rgba(186,228,238,0.1)"
      >
        <TwoWaysSection>
          <Headline>Two ways to Group Order</Headline>
          <Bullets>
            {bulletPoints.map((bullet, i) => (
              <BulletPoint
                heading={bullet.heading}
                body={bullet.body}
                markerText={(i + 1).toString()}
                key={i}
              />
            ))}
          </Bullets>
          <Image
              src="https://res.cloudinary.com/grubhub-marketing/image/upload/f_auto/v1626797841/Grubhub%20Corporate%20Website/2021/Photography%20Elements/3-Subpage-CircleImages/EXPORTS-IMAGE-Circle-GroupOrdering.png"
              alt="Man standing removing and stacking food items out of a brown paper bag."
          />
        </TwoWaysSection>
      </SectionWrapper>
      <QuoteSection
        quote='"Our corporate account has simplified our food ordering process and alleviated administrative management thanks to group ordering, which works great for us. It eliminates the need to order individually."'
        author="Key Chong"
        position="Director of People at HP"
        imageSrc="https://res.cloudinary.com/grubhub-marketing/image/upload/v1627927171/Grubhub%20Corporate%20Website/2021/Partner%20Logos/PARTNERLOGOS-HP.png"
        alt="HP logo"
        backgroundColor="#FFF"
      />
      <CardsSection backgroundColor="rgba(186,228,238,0.1)" headline="More ways to order" cards={cards} isSliderOnMobile/>
      <CtaSection ctaText="Get started" headline="Discover the industry leader in flexible meal benefits" backgroundColor="#F9F9F9" to="/contact-us/"/>
      <FaqAccordionSection faqs={faqs} backgroundColor="white"/>
    </Layout>
  )
}

export default GroupOrders

const TwoWaysSection = styled.div`
  display: grid;
  grid-template-areas: 
      "headline image"
      "bullets image"
  ;
  grid-template-columns: 6fr 4fr;
  @media (${queries.max_break_md}){
    grid-template-areas: 
      "headline"
      "image"
      "bullets";
    grid-template-columns: auto;
  }
`;

const Headline = styled.h2`
  grid-area: headline;
  font-size: var(--fontSize-5);
  font-weight: bold;
  margin-bottom: 25px;
  align-self:flex-end;
  @media (${queries.max_break_md}){
    text-align: center;
  }
`;
const Bullets = styled.div`
  grid-area: bullets;
`;
const Image = styled.img`
  grid-area: image;
  align-self: center;
  width:100%;
  @media (${queries.max_break_md}){
    margin: 20px 0%;
  }
`;
